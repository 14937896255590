import React from "react";
import { useLocation } from "@reach/router";
import {ComplexNavbar, ComplexNavbarHome} from "./navbar/MaterialNav";
import CountdownTimer from "./Countdown";
import {HostLogosPage} from "./HostLogos";
import SponsScrollBar from "./SponsScrollBar";
import { Button } from "@/components/ui/button"
import { Link } from "gatsby"

const Header = () => {
  const location = useLocation();

  // Determine background image, heading, and description based on the current URL
  let backgroundImage;
  let heading;
  let description;

  if (location.pathname === "/") {
    backgroundImage = "/images/Arc-APAC-Summit-2025_md-bg.webp";
    heading = "Home";
    description = "Welcome to ARC APAC Summit 2025 official website. You can acquire latest information about the conference here.";
  } else if (location.pathname === "/promise/") {
    backgroundImage = "/images/arc_defaultbanner.webp";
    heading = "Promise of the ARC APAC Summit";
    description = "The ARC Asia Pacific Summit isn’t just an event; it’s a bold declaration—a promise etched in the fabric of healthcare innovation.";
  } else if (location.pathname === "/host/") {
    backgroundImage = "/images/arc_defaultbanner.webp";
    heading = "Host: ARC Innovation";
    description = "The Host, ARC Innovation, founded in 2019, is driving the redesign of global healthcare.";
  } else if (location.pathname === "/committee/") {
    backgroundImage = "/images/arc_defaultbanner.webp";
    heading = "Committee";
    description = "Get to know about our Committee who organises this meaningful conference";
  } else if (location.pathname === "/registration/") {
    backgroundImage = "/images/arc_defaultbanner.webp";
    heading = "Registration";
    description = "Register and join the ARC APAC Summit 2025.";
  } else if (location.pathname === "/accommodation/") {
    backgroundImage = "/images/arc_defaultbanner.webp";
    heading = "Accommodation";
    description = "Explore in the world city Melbourne and Victoria.";
  } else if (location.pathname === "/destination/") {
    backgroundImage = "/images/arc_defaultbanner.webp";
    heading = "Destination";
    description = "Explore in the world city Melbourne and Victoria.";
  } else if (location.pathname === "/venue/") {
    backgroundImage = "/images/arc_defaultbanner.webp";
    heading = "Venue";
    description = "The ARC APAC Summit 2025 will be held in Melbourne Convention and Exhibition Centre.";
  } else if (location.pathname === "/visa-information/") {
    backgroundImage = "/images/arc_defaultbanner.webp";
    heading = "Visa Information";
    description = "View the process and terms to apply for visa.";
  } else if (location.pathname === "/partnerships/") {
    backgroundImage = "/images/arc_defaultbanner.webp";
    heading = "Partnerships";
    description = "An exciting range of partnership opportunities have been developed.";
  } else if (location.pathname === "/contact/") {
    backgroundImage = "/images/arc_defaultbanner.webp";
    heading = "Contact Us";
    description = "Contact the ARC APAC Summit Conference Secretariat for any inquiry.";
  } else if (location.pathname === "/themes/") {
    backgroundImage = "/images/arc_defaultbanner.webp";
    heading = "Themes";
    description = "Explore the themes of the ARC APAC Summit 2025.";
  } else if (location.pathname === "/speakers/") {
    backgroundImage = "/images/arc_defaultbanner.webp";
    heading = "Speakers";
    description = "Explore the speakers of the ARC APAC Summit 2025.";
  } else if (location.pathname === "/partners/") {
    backgroundImage = "/images/arc_defaultbanner.webp";
    heading = "Partners";
    description = "Explore the partners who contribute to the ARC APAC Summit 2025.";
  } else if (location.pathname === "/latest-news/") {
    backgroundImage = "/images/arc_defaultbanner.webp";
    heading = "Latest News";
    description = "Read the archives of newsletter and communications for ARC APAC Summit 2025. Join our mailing list by signing up in the Expression of Interest form.";
  } else if (location.pathname === "/preliminary-program/") {
    backgroundImage = "/images/arc_defaultbanner.webp";
    heading = "Preliminary Program";
    description = "The ARC APAC Program Overview is subject to change and will be updated with exciting changes soon.";
  }
  

  return (
    <>
    {heading == 'Home' ? 
    // Homepage header
    <>
    <header
        className="relative text-white h-[100vh] flex flex-col !w-full !overflow-x-hidden"
    >
      <div style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '100vh',
        }} className="flex flex-col">
        <div className="absolute inset-0"></div>
        <ComplexNavbarHome />
        <section className="flex grow">
          <div className="2xl:container px-4 mx-auto flex flex-col justify-center lg:gap-12 gap-5">
            <div className="flex flex-col lg:gap-12 gap-8">
              <div className="flex flex-col lg:gap-8 gap-6 justify-center items-center">
                <img src="/logo/arcapac25_logo_rev.svg" alt="ARC APAC Summit 2025 Logo" className="w-[800px] max-w-[98%]" />
                <img src="/images/slogan.svg" alt="ARC APAC Summit 2025 Slogan" className="w-[600px] max-w-[100%]" />
                <div className="max-md:hidden flex lg:flex-row flex-col gap-4 justify-center items-center z-[990] py-2 px-6 rounded-full border border-hyelo-300">
                  <p className="mb-0 text-white">Early Bird Registration Extended to 15 February 2025</p>
                  <Link to="/registration"><Button>Register Now</Button></Link>
                </div>
                  <div className="flex flex-col mx-auto items-center justify-center max-w-[300px] !lg:max-w-[400px]">
                    <CountdownTimer targetDate="2025-03-12" /> 
                  </div>
              </div>
            </div>
            {/* <div className="flex justify-center">
              <Link to="#welcome">
                <Button className="p-6 rounded-full text-white bg-transparent hover:bg-hturq-500 border-2 border-white hover:border-transparent hover:bg-gradient-to-br hover:from-hturq-800 hover:to-hturq-550 transition duration-500 ease-in-out">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="w-4 fill-current text-white"><path d="M246.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-160-160c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L224 402.7 361.4 265.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3l-160 160zm160-352l-160 160c-12.5 12.5-32.8 12.5-45.3 0l-160-160c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L224 210.7 361.4 73.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3z"/></svg>
                </Button>
              </Link>
            </div> */}
          </div>
        </section>
        </div>
        <div className="sticky bottom-0 bg-white pt-6">
          <SponsScrollBar />
        </div>
      </header>
    </>
    : 
    // Subpages header
    <> 
      <header
          className="text-white relative h-96"
          style={{
            backgroundImage: `url('/images/arc_defaultbanner.webp')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
      >
        <div className="inset-0 bg-hturq-500/20"></div>
        <ComplexNavbar/>

        <section className="flex justify-center lg:justify-between content-center">
          <div className="lg:container lg:mx-auto px-4 md:px-6 flex flex-row lg:justify-between justify-center lg:items-end pb-4">
            <div className="flex justify-center lg:justify-start items-end">
              
            </div>
          </div>
        </section>
        {/* <div class="flex justify-end lg:container lg:mx-auto px-4 md:px-6">
          <h1 className="text-white mb-0 pb-0 text-center lg:text-start drop-shadow-lg">{heading}</h1>
        </div> */}

        <div className="absolute right-0 bottom-0 hidden lg:block">
          <HostLogosPage />
        </div>
      </header>
      </>
    }
    </>
  );
}

export default Header;
