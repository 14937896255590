const sponsors = [
    //Platinum
    { 
        name: "Silverchain Group", 
        website: "https://silverchain.org.au/", 
        bio: "Silverchain Group is one of Australia’s leading home care specialists, providing health and aged care services to more than 140,000 clients each year. Trusted by Australians to deliver care that is differentiated by quality and safety for 130 years, we aim to revolutionise the way care is provided and to transform the way it is received. We provide complex and acute nursing; hospital in the home; specialist community palliative care; home help, support to live independently and wellbeing services; allied health services; digital enabled care and remote health monitoring; and chronic disease management.", 
        order: 1 
    },
    //Gold
    { 
        name: "Sectra", 
        website: "https://sectra.com/", 
        bio: "Sectra is a leading provider of medical imaging IT and cybersecurity solutions with a vision to create a healthier and safer society. With over 2,500 global installations, Sectra prioritizes customer value and satisfaction, equipping healthcare organisations with advanced enterprise imaging solutions that enhance productivity and support positive patient outcomes.", 
        order: 1 
    },
    //Silver
    { 
        name: "Altera Digital Health", 
        website: "https://www.alterahealth.com/", 
        bio: "Altera Digital Health delivers global healthcare technology solutions, including dbMotion™, which unifies data from diverse sources into a longitudinal health record to enhance patient care.", 
        order: 1 
    },
    { 
        name: "Boston Scientific", 
        website: "https://www.bostonscientific.com/en-US/home.html", 
        bio: "Boston Scientific’s portfolio of devices and therapies help physicians diagnose and treat complex cardiovascular, respiratory, digestive, oncological, neurological and urological diseases and conditions.", 
        order: 2 
    },
    { 
        name: "Heart Foundation", 
        website: "https://www.heartfoundation.org.au/", 
        bio: "The Heart Foundation is Australia’s leading heart health charity, dedicated to funding life-saving research, and improving heart disease prevention, detection and support.", 
        order: 3 
    },
    { 
        name: "Regeneron", 
        website: "https://www.regeneron.com/", 
        bio: "Regeneron is a leading biotechnology company that invents, develops and commercializes life-transforming medicines for people with serious diseases.", 
        order: 4 
    },
    { 
        name: "Square Peg", 
        website: "https://www.squarepeg.vc/", 
        bio: "Square Peg is a venture capital firm that backs founders from three of technology’s most exciting regions: Australia & New Zealand, Israel, and Southeast Asia.", 
        order: 5
    },
    //Regional
    { 
        name: "ASME", 
        website: "https://www.asme.org.au/",
        bio: "The Australian Society for Medical Entrepreneurship & Innovation (ASME) was founded in 2023 as a not-for-profit medical society that recognises the unique ability of clinicians to identify opportunities to transform healthcare. Our purpose is to inspire and empower more clinician-led innovation to drive advancements in healthcare and we do this through advocacy, education, mentorship and networking. We are proud to leverage our local networks to support the ARC APAC Summit as its Regional Collaborator. Learn more about ASME here: <a href='https://www.asme.org.au/'>www.asme.org.au</a>",
        order: 1 
    },
    { 
        name: "MCB", 
        website: "https://www.melbournecb.com.au/", 
        order: 1 
    },
    { 
        name: "Melbourne-Australia", 
        website: "https://www.vic.gov.au/", 
        order: 2 
    },
];

export default sponsors;